const getPatientInfo = () => {
  return {
    id: localStorage.getItem("patient_id"),
    nric: localStorage.getItem("patient_nric"),
    email: localStorage.getItem("email"),
    firstname: localStorage.getItem("firstname"),
  };
};

const getToken = () => {
  return localStorage.getItem("token");
};

const token = getToken();
const setIsLoggedIn = localStorage.getItem("setIsLoggedIn");
const patient_id = localStorage.getItem("patient_id");
const patient_nric = localStorage.getItem("patient_nric");
const email = localStorage.getItem("email");
const firstname = localStorage.getItem("firstname");
const language = localStorage.getItem("selectedLanguage");

module.exports = {
  token,
  setIsLoggedIn,
  patient_id,
  patient_nric,
  email,
  firstname,
  language,
  getPatientInfo,
  getToken,
};
