import axios from "axios";
import { get_seo_link, meta_data } from "../apiConfig";
import { getToken } from "../../localstorage";

// seo
const api_seo = async (keyword, token, type) => {
  return axios
    .get(get_seo_link, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${token}`,
      },
      params: {
        keyword: keyword,
        type: type,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

// meta_data
const api_meta_data = async (module, item_id, item_slug) => {
  return axios
    .get(meta_data, {
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "kpjhealth",
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        module: module,
        item_id: item_id,
        slug: item_slug,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export { api_seo, api_meta_data };
