import { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "../localstorage";

const useAxios = (url, params = {}, dependencyArray = []) => {
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": "kpjhealth",
            Authorization: `Bearer ${getToken()}`,
          },
          params: params,
        });
        setResponseData(response.data);
        setError(null);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, ...dependencyArray]);

  return { responseData, loading, error };
};

export default useAxios;
