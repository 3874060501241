import firebaseInitialization from "../components/Firebase/Firebase.init";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";
import { useState } from "react";
import Swal from "sweetalert2";
import { post_firebase_sso } from "../api/apiConfig";
import { getToken } from "../localstorage";
import axios from "axios";

firebaseInitialization();

const useFirebase = () => {
  const [user, setUser] = useState({});
  const auth = getAuth();

  // login with google
  const googleProvider = new GoogleAuthProvider();
  const googleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;

        const postData = {
          email: user.email,
          token: user.stsTokenManager.accessToken,
        };

        axios
          .post(post_firebase_sso, postData, {
            headers: {
              "Content-Type": "application/json",
              "X-API-KEY": "kpjhealth",
              Authorization: `Bearer ${getToken()}`,
            },
          })
          .then((response) => {
            const { data } = response;
            if (data.status) {
              const { account } = data;
              localStorage.setItem("token", data.token);
              localStorage.setItem("firstname", account.firstname);
              localStorage.setItem("patient_id", account.user_id);
              localStorage.setItem("patient_nric", account.nric);

              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              Swal.fire({
                icon: "error",
                title: "Login",
                text: "Invalid email or password. Please try again.",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Login",
              text: error.message || "An error occurred during login.",
            });
          });
      })
      .catch((error) => {
        const ignore_these_codes = [
          'auth/popup-closed-by-user',
          'auth/cancelled-popup-request',
        ]
        // dont show error if user cancelled themselves
        if(!ignore_these_codes.includes(error.code)){
          const errorMessage = error.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage,
          });
        }
      });
  };

  //   login with facebook
  const facebookProvider = new FacebookAuthProvider();
  const facebookSignIn = () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        const user = result.user;
      })
      .catch((error) => {
        const errorMessage = error.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
        });
      });
  };

  return {
    user,
    googleSignIn,
    facebookSignIn,
  };
};
export default useFirebase;
