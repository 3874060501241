import React, {useState} from "react";
import DoctorImage from "../../../assets/whoweare/doctor.png"

function WhoAreWeInfoSection () {
    const [isFirst, setIsFirst] = useState(true);

    const bigIndex = isFirst ? "01\u00A0" : "02\u00A0";
    const smallIndex = isFirst ? "/02" : "/01";
    const desc = isFirst ? "KPJ has a resilient business model, drawn from our 38 years of experience in the Malaysian Private Healthcare Industry." 
                         : "Our value ecosystem centres on managing a network of specialist hospitals, supported by strategic investments in a diversified portfolio. We are in a position to create sustainable long-term value for all our stakeholders.";
                         
    const handleArrowClick = () => {
        setIsFirst(!isFirst);
    };

    return (
        <>
        <div
          className="who-we-are-intro-section wwa_intro_web"
          style={{
            backgroundImage: `url(${DoctorImage})`,
            position: "relative",
          }}
        >
          <div className="container">
          <div className="brown-card">
            <div style={{display:"flex", flexDirection:"row"}}>
                <h2>{bigIndex}</h2>
                <h5>{smallIndex}</h5>
            </div>
            <h3>{desc}</h3>
            <img
              id="arrow_circle_icon"
              src="./../img/insight/circle-arrow-insight.png"
              alt=""
              onClick={handleArrowClick}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        </div>

        <div className="who-we-are-intro-container">
          <p>
            In addition, KPJ has made outstanding inroads in the application of integrated Group-wide technology. The adoption of Cloud Computing has further enhanced the ability of the medical consultants in assessing patient information in real-time, to ensure delivery of a seamless service. To further optimise the handling of patients' records and increase hospital efficiency, continuous improvements are being undertaken to advance the KPJ Clinical Information 
            System (KCIS). <br/><br/>

            Apart from clinical technology located in its hospitals, KPJ is also exploring opportunities to apply digitalized medical technology in everyday life. Among 
            the ventures in the pipeline are KPJ's E-Pharmacy with online ordering, wearable technology, robotics, Artificial Intelligence (Al) and the Internet of Things 
            (loT). <br/><br/>

            The Group's extensive experience in hospital development and management spanning more than three and a half decades also means that the Group is  built on strong, dependable fundamentals. These serve as a solid bedrock as the industry continues to evolve and change over time. <br/><br/>

            In growing the health tourism segment, our aggressive marketing strategies continue to show stellar results as we have expanded our footprint in Asia, 
            the Middle Eastern and North African region, the Indian Sub-Continent, China and the Pacific Islands. <br/><br/>

            Apart from hospital-based care, the KPJ Group made its mark in healthcare-related industries, primarily KPJ Senior Living Care services and Healthcare  Education. These thriving sub-sectors hold tremendous potential for the future, in line with the increasing consumer demand. <br/><br/>

            Our hospitals continue to be recognised by accreditation bodies such as the Malaysian Society for Quality in Health (MSQH) and the Joint Commission 
            International (XI). KPJ hospitals have been certified by Integrated Management System (IMS) that integrates and emphasises on the Quality Management 
            System (MS ISO 9001:2015) ;Environment (MS ISO 14001:2015); Occupational Safety and Health (OHSAS 18001:1999) Systems as well as other ISO and 
            SIRIM certifications. <br/><br/>

            KPJ has always kept the communities interest close to heart, and we reach out to the underprivileged through various channels. The Community Service is the provision of care through our network of Klinik Wakaf An-Nur (KWAN) charity clinics. There are 18 KWAN clinics and 1 Hospital Waqaf An-Nur (HWAN) in Malaysia today, as well as five mobile clinics in Kuala Lumpur, Johor, Selangor and Kelantan. <br/><br/>

            Our efforts have enabled more than one million patients since the inception of the first clinic in Johor in 1998. <br/><br/>
          </p>
          </div>
        </>
    )
}

export default WhoAreWeInfoSection;