import React from "react";
import banner from "../../../assets/whoweare/banner.png";

function WhoWeAreHero() {
  return (
    <>
      <div
          className="who-we-are-hero-section wwa_hero_web"
          style={{
            backgroundImage: `url(${banner})`,
            position: "relative",
          }}
        >
          <div className="container">
            <h1>Who We Are</h1>
          </div>
      </div>

      {/* mobile view */}
      <div
        className="who-we-are-hero-section wwa_mob_web"
        style={{
          backgroundImage: `url(${banner})`,
          position: "relative",
        }}
      >
        <div className="container">
          <p className="wwa_mobile_title">Who We Are</p>
        </div>
      </div>
    </>
  );
}

export default WhoWeAreHero;
