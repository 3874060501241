import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/Shared/Footer';
import PaymentContent from './PaymentContent/PaymentContent';

const Fail = () => {
    return (
        <>
           <CommonPageHeader title="Payment Failed" subtitle="Payment Failed" />
           <PaymentContent title="Ooops!" message="Looks like something went wrong. The payment attempt was not successful. Please try again."/>
           <Footer/>
        </>
    );
};

export default Fail;