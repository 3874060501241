const HospitalWithLocations = [
  {
    hospital_name: "Damansara Specialist Hospital 2",
    location: "3.1711032449817322, 101.61834586834883",
    state: "Kuala Lumpur",
  },
  {
    hospital_name: "Kedah Medical Centre",
    location: "6.149247135974945, 100.36949896466655",
    state: "Kedah",
  },
  {
    hospital_name: "KPJ Ampang Puteri Specialist Hospital",
    location: "3.16035487225788, 101.75159859348278",
    state: "Selangor",
  },
  {
    hospital_name: "KPJ Bandar Dato Onn Specialist Hospital",
    location: "1.550319837749612, 103.74042302288288",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Bandar Maharani Specialist Hospital",
    location: "2.0962875187283645, 102.56451339390414",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Batu Pahat Specialist Hospital",
    location: "1.8685327790199069, 102.9913879263021",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Damansara Specialist Hospital",
    location: "3.1378131028882588, 101.6275779106769",
    state: "Selangor",
  },
  {
    hospital_name: "KPJ Ipoh Specialist Hospital",
    location: "4.5947890825157245, 101.09640329534155",
    state: "Perak",
  },
  {
    hospital_name: "KPJ Johor Specialist Hospital",
    location: "1.4760949051393473, 103.74021947998489",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Kajang Specialist Hospital",
    location: "3.0000807230945425, 101.78566415115382",
    state: "Selangor",
  },
  {
    hospital_name: "KPJ Klang Specialist Hospital",
    location: "3.0624429401836806, 101.46323029533329",
    state: "Selangor",
  },
  {
    hospital_name: "KPJ Kluang Specialist Hospital",
    location: "2.0133443310923895, 103.27487411067288",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Kuching Specialist Hospital",
    location: "1.5061772513308462, 110.36591536649247",
    state: "Sarawak",
  },
  {
    hospital_name: "KPJ Miri Specialist Hospital",
    location: "4.448190332897586, 114.03391635116131",
    state: "Sarawak",
  },
  {
    hospital_name: "KPJ Pahang Specialist Hospital",
    location: "3.800155085379161, 103.33663342417267",
    state: "Pahang",
  },
  {
    hospital_name: "KPJ Pasir Gudang Specialist Hospital",
    location: "1.4771875775696914, 103.89580582416409",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Penang Specialist Hospital",
    location: "5.3693756751170865, 100.4343359106904",
    state: "Penang",
  },
  {
    hospital_name: "KPJ Perdana Specialist Hospital",
    location: "6.121945349897216, 102.24361992418895",
    state: "Kelantan",
  },
  {
    hospital_name: "KPJ Perlis Specialist Hospital",
    location: "6.43337347397805, 100.18650833953501",
    state: "Perlis",
  },
  {
    hospital_name: "KPJ Puteri Specialist Hospital",
    location: "1.4908954286077052, 103.7420715664924",
    state: "Johor",
  },
  {
    hospital_name: "KPJ Rawang Specialist Hospital",
    location: "3.3210517238540995, 101.5792404376627",
    state: "Selangor",
  },
  {
    hospital_name: "KPJ Sabah Specialist Hospital",
    location: "5.967467325199535, 116.09317598000835",
    state: "Sabah",
  },
  {
    hospital_name: "KPJ Selangor Specialist Hospital",
    location: "3.561721535888013, 101.6514754861255",
    state: "Selangor",
  },
  {
    hospital_name: "KPJ Sentosa KL Specialist Hospital",
    location: "3.1710739243380814, 101.69711075331826",
    state: "Kuala Lumpur",
  },
  {
    hospital_name: "KPJ Seremban Specialist Hospital",
    location: "2.718750049230117, 101.92181526649605",
    state: "Negeri Sembilan",
  },
  {
    hospital_name: "KPJ Sibu Specialist Medical Centre",
    location: "2.3010383023253653, 111.83202257998694",
    state: "Sarawak",
  },
  {
    hospital_name: "KPJ Sri Manjung Specialist Centre",
    location: "4.20870789331324, 100.67507085301081",
    state: "Perak",
  },
  {
    hospital_name: "KPJ Tawakkal KL Specialist Hospital",
    location: "3.177122794338061, 101.69863166649795",
    state: "Kuala Lumpur",
  },
  {
    hospital_name: "Taiping Medical Centre",
    location: "4.8474305234621085, 100.73242517999981",
    state: "Perak",
  },
];

export { HospitalWithLocations };
