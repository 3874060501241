import React, { useEffect, useContext, useRef } from "react";
import { AuthContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";
import { get_settings } from "../api/apiConfig";

const IdleTimeout = () => {
  const { handleLogout } = useContext(AuthContext);
  const { responseData, loading, error } = useAxios(get_settings);

  //useRef to keep track of last activity time
  const lastActivityTimeRef = useRef(new Date());

  useEffect(() => {
    if (!responseData || loading || error) {
      return;
    }

    const idleTimeout = parseInt(responseData.data.timeout); // Convert to number
    let timeout;

    const resetIdleTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(logout, idleTimeout * 60 * 1000);
    };

    const logout = () => {
      const currentTime = new Date();
      const idleTime = currentTime - lastActivityTimeRef.current;
      // Check if the idle time exceeds the allowed idle timeout
      if (idleTime >= idleTimeout * 60 * 1000) {
        handleLogout();
        window.location.reload();
      } else {
        // If not, reset the idle timeout
        resetIdleTimeout();
      }
    };

    const handleUserActivity = () => {
      // Update the last activity time whenever there's user activity
      lastActivityTimeRef.current = new Date();
      resetIdleTimeout();
    };

    const initializeIdleTimeout = () => {
      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
      resetIdleTimeout();
    };

    initializeIdleTimeout();

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timeout);
    };
  }, [responseData, loading, error]);

  return null;
};

export default IdleTimeout;
