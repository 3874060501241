const merchant_id = process.env.REACT_APP_RAZER_MERCHANT_ID; 
const secret = process.env.REACT_APP_RAZER_SECRET; 
const verify = process.env.REACT_APP_RAZER_VERIFY; 

const RAZER_CONSTANTS = {
  MERCHANT_ID: merchant_id,
  SECRET: secret,
  VERIFY: verify,
  // PAYMENT_URL: 'https://pay.merchant.razer.com/RMS/pay',
  // RETURN_IPN_URL: 'https://pay.merchant.razer.com/RMS/API/chkstat/returnipn.php',
  PAYMENT_URL: 'https://sandbox.merchant.razer.com/RMS/pay', // sandbox
  RETURN_IPN_URL: 'https://sandbox.merchant.razer.com/RMS/API/chkstat/returnipn.php', // sandbox
};

export default RAZER_CONSTANTS;
