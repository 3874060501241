import React from "react";

const PaymentContent = ({title, message}) => {
  return (
    <section className="payment-message-area pt-100 pb-100">
      <div className="container">
        <div className="row text-center">
          <h2>{title}</h2>
          <h4 className="mt-2 mb-3">{message}</h4>
          <h4>Thank You.</h4>
        </div>
      </div>
    </section>
  )
}

export default PaymentContent;