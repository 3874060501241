import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/Shared/Footer';
import PaymentContent from './PaymentContent/PaymentContent';

const Success = () => {
    return (
        <>
           <CommonPageHeader title="Payment Success" subtitle="Payment Success" />
           <PaymentContent title="Thank You" message="We have received your transaction details. We will be in touch in the next 48hours to confirm the reservation and purchase."/>
           <Footer/>
        </>
    );
};

export default Success;