import React, { createContext, useState, useEffect, useContext } from "react";
import { handle_login, get_token } from "../api/auth";
import { token } from "../localstorage";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isGuest, setIsGuest] = useState(true);
  const [Patientid, setPatientid] = useState(false);
  const [LoggedInToken, setLoggedInToken] = useState("");
  const [profileChangePic, setProfileChangePic] = useState("");
  const { updateQuantity, updatecartItems } = useContext(CartContext);

  const navigate = useNavigate();

  const handleLogin = (email, password, closeLoginPage) => {
    handle_login(
      email,
      password,
      setIsLoggedIn,
      setIsGuest,
      closeLoginPage,
      setLoggedInToken
    );
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setPatientid(false);
    setLoggedInToken("");
    updatecartItems([]);
    updateQuantity(0);

    localStorage.removeItem("token");
    localStorage.removeItem("firstname");
    localStorage.removeItem("patient_id");
    localStorage.removeItem("patient_nric");

    get_token("get_token")
      .then((data) => {
        localStorage.setItem("token", data.token);

        setIsGuest(true);
        navigate("/");
      })
      .catch((error) => {
        console.log("get_token API error:", error);
      });
  };

  useEffect(() => {
    let isMounted = true;

    const patient_id = localStorage.getItem("patient_id");
    if (patient_id) {
      setPatientid(patient_id);
    }

    checkTokenValidity()
      .then((isValid) => {
        if (isMounted) {
          if (!isValid) {
            get_token("get_token")
              .then((data) => {
                localStorage.setItem("token", data.token);
                setIsGuest(true);
              })
              .catch((error) => {
                console.log("get_token API error:", error);
              });
          }
        }
      })
      .catch((error) => {
        console.log("Check token API error:", error);
      });

    return () => {
      isMounted = false;
    };
  }, [token, handleLogout, handleLogin]);

  const handlechangePic = (image) => {
    setProfileChangePic(image);
  };

  const checkTokenValidity = async () => {
    // token validation check at app.js
    return true;
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isGuest,
        setIsGuest,
        setLoggedInToken,
        handleLogin,
        handleLogout,
        Patientid,
        setPatientid,
        LoggedInToken,
        profileChangePic,
        handlechangePic,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//Tab Context to handle Public/Investor
const TabContext = createContext();

const TabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("Public");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <TabContext.Provider value={{ activeTab, handleTabClick }}>
      {children}
    </TabContext.Provider>
  );
};

//Language Context to handle language option
const LangContext = createContext();

const LangProvider = ({ children }) => {
  const initialLanguage = localStorage.getItem("selectedLanguage") || "en";
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <LangContext.Provider value={{ selectedLanguage, handleLanguageChange }}>
      {children}
    </LangContext.Provider>
  );
};

// FILTER CONTEXT
const CombinedFilterContext = createContext();

const CombinedFilterProvider = ({ children }) => {
  const [hospitalLocation, setHospitalLocation] = useState("");

  const [doctorFilters, setDoctorFilters] = useState({
    location: "",
    specialisation: "",
    gender: "",
    filter_lang: "",
    doctor_name: "",
  });

  const [healthPackageFilters, setHealthPackageFilters] = useState({
    type: "",
    age: "",
    gender: "",
    location: "",
  });

  const [camproFilters, setCamproFilters] = useState({
    month: "",
    year: "",
    location: "",
    gender: "",
    specialisation: "",
    sortBy: "",
  });

  const [eventsFilters, setEventsFilters] = useState({
    month: "",
    year: "",
    location: "",
    gender: "",
    specialisation: "",
    sortBy: "",
  });

  const [videosFilters, setVideosFilters] = useState({
    type: "",
    topic: "",
    language: "",
    sortBy: "",
    month: "",
    year: "",
  });

  const [podcastsFilters, setPodcastsFilters] = useState({
    type: "",
    topic: "",
    language: "",
    sortBy: "",
    month: "",
    year: "",
  });

  const [articlesFilters, setArticlesFilters] = useState({
    type: "",
    topic: "",
    language: "",
    sortBy: "",
    month: "",
    year: "",
  });

  const resetDoctorFilters = () => {
    setDoctorFilters({
      location: "",
      specialisation: "",
      gender: "",
      filter_lang: "",
      name: "",
    });
  };

  const resetHealthPackageFilters = () => {
    setHealthPackageFilters({
      type: "",
      age: "",
      gender: "",
      location: "",
    });
  };

  return (
    <CombinedFilterContext.Provider
      value={{
        hospitalLocation,
        setHospitalLocation,
        doctorFilters,
        setDoctorFilters,
        healthPackageFilters,
        setHealthPackageFilters,
        camproFilters,
        setCamproFilters,
        eventsFilters,
        setEventsFilters,
        videosFilters,
        setVideosFilters,
        podcastsFilters,
        setPodcastsFilters,
        articlesFilters,
        setArticlesFilters,
        resetDoctorFilters,
        resetHealthPackageFilters,
      }}
    >
      {children}
    </CombinedFilterContext.Provider>
  );
};

//cart
const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cartItemsQuantity, setCartItemsQuantity] = useState();
  const [cartItems, setCartItems] = useState();

  const updateQuantity = (newQuantity) => {
    setCartItemsQuantity(newQuantity);
  };

  const updatecartItems = (cartItems) => {
    const CaCartItems = cartItems?.map((item) => ({
      ...item,
      category: item.healthpackage_type_name,
      totalUnitPrice: item.cart_quantity * item.healthpackage_price,
      isSelected: item.isSelected,
    }));

    setCartItems(CaCartItems);
  };

  return (
    <CartContext.Provider
      value={{ cartItemsQuantity, updateQuantity, cartItems, updatecartItems }}
    >
      {children}
    </CartContext.Provider>
  );
};

export {
  AuthContext,
  AuthProvider,
  TabContext,
  TabProvider,
  LangContext,
  LangProvider,
  CartContext,
  CartProvider,
  CombinedFilterContext,
  CombinedFilterProvider,
};
